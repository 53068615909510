import { useEffect, useState } from "react";
import { Flex, Space, Table, Tooltip, Input, Button } from "antd";
import { formatDate } from "../../../../utils/formatDate";
import { copyToClipBoard } from "../../../../utils/copyToClipboard";
import {
	CopyOutlined,
	InfoCircleOutlined,
	SearchOutlined,
	WarningFilled,
} from "@ant-design/icons";
import { useDashboardContext } from "../../../../store/auth/dashboardContext";
import { ViewJsonTree } from "./ProcessTable/viewJsonModal";
import { Box } from "@material-ui/core";
import { ProcessLogsType, TenantProcessTypesType } from "../../../../types";

// const onChange = (pagination, filters, sorter, extra) => {
//   console.log("params", pagination, filters, sorter, extra);
// };

interface ProcessTablePropsType {
	tenantProcessTypes: TenantProcessTypesType;
	processLogs: ProcessLogsType[];
}

const ProcessTable = ({
	tenantProcessTypes,
	processLogs,
}: ProcessTablePropsType) => {
	const { tenantId, isLoading } = useDashboardContext();
	// const [data, setData] = useState<any>([]);
	const [filterProcessTypes, setFilterProcessTypes] =
		useState<{ text: string; value: number }[]>();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [searchText, setSearchText] = useState("");
	const [searchedColumn, setSearchedColumn] = useState("");
	const [viewJsonData, setViewJsonData] = useState({
		data: "",
		processId: "",
		objectType: "",
	});

	useEffect(() => {
		const mappedFilters = tenantProcessTypes[tenantId].map(
			(processType: any) => ({
				text: processType, // label for mui
				value: processType,
			})
		);

		setFilterProcessTypes(mappedFilters);
	}, [tenantProcessTypes, processLogs]);

	const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
		confirm();
		setSearchText(selectedKeys[0]);
		setSearchedColumn(dataIndex);
	};

	// Function to reset the search
	const handleReset = (clearFilters: any) => {
		clearFilters();
		setSearchText("");
	};

	const handleViewJson = (data: string, record: any, objectType: string) => {
		const processId: string = record.process_id;

		setViewJsonData({ data, processId, objectType });
		setIsModalOpen(true);
	};

	const handleClose = () => {
		setIsModalOpen(false);
	};

	const columns: any = [
		{
			title: "Process Id",
			dataIndex: "process_id",
			width: 100,
			sorter: (a: any, b: any) => a.process_id - b.process_id,
		},
		{
			title: "Start Time",
			width: 150,
			dataIndex: "start_datetime",
			render: (date: string) => <>{formatDate(date)}</>,
		},
		{
			title: "End Time",
			width: 150,
			render: (date: string) => <>{formatDate(date)}</>,
			dataIndex: "end_datetime",
		},
		{
			title: "Process Type",
			dataIndex: "process_type",
			width: 160,
			filters: filterProcessTypes,
			onFilter: (value: any, record: any) =>
				record.process_type.includes(value),
		},
		{
			title: "Status",
			dataIndex: "status",
			width: 80,
			filters: [
				{
					text: "Started",
					value: "STARTED",
				},
				{
					text: "Ended",
					value: "ENDED",
				},
				{
					text: "Failed",
					value: "FAILED",
				},
				// {
				//   text: "Retry",
				//   value: "RETRY",
				// },
			],
			onFilter: (value: any, record: any) =>
				record.status.includes(value),
			render: (status: string) => (
				<Space>
					{status}
					{status === "FAILED" ? (
						<WarningFilled
							rev={undefined}
							style={{ color: "#f56e53" }}
						/>
					) : (
						""
					)}
				</Space>
			),
		},
		{
			title: "Retries",
			dataIndex: "retry_attempted",
			width: 60,
		},
		{
			title: "Event Ref",
			dataIndex: "event_reference",
			width: 100,
			filterDropdown: ({
				setSelectedKeys,
				selectedKeys,
				confirm,
				clearFilters,
			}: any) => (
				<div style={{ padding: 8 }}>
					<Input
						placeholder="Search Event Ref"
						value={selectedKeys[0]}
						onChange={(e) =>
							setSelectedKeys(
								e.target.value ? [e.target.value] : []
							)
						}
						onPressEnter={() =>
							handleSearch(
								selectedKeys,
								confirm,
								"event_reference"
							)
						}
						style={{
							width: 188,
							marginBottom: 8,
							display: "block",
						}}
					/>
					<Button
						type="primary"
						onClick={() =>
							handleSearch(
								selectedKeys,
								confirm,
								"event_reference"
							)
						}
						icon={<SearchOutlined rev={undefined} />}
						size="small"
						style={{ width: 90, marginRight: 8 }}
					>
						Search
					</Button>
					<Button
						onClick={() => handleReset(clearFilters)}
						size="small"
						style={{ width: 90 }}
					>
						Reset
					</Button>
				</div>
			),
			onFilter: (value: any, record: any) =>
				record["event_reference"]
					? record["event_reference"]
							.toString()
							.toLowerCase()
							.includes(value.toLowerCase())
					: false,
			render: (data: any) => <>{!data ? "-" : data}</>,
		},
		{
			title: "Event Object",
			dataIndex: "event_object",
			ellipsis: true,
			width: 110,
			render: (data: any, record: any) => (
				<Flex justify="center">
					{/* <div className="ellipsis-div">{!data ? "-" : data}</div> */}
					{data ? (
						<Space>
							<Tooltip placement="bottom" title="Copy">
								<CopyOutlined
									onClick={() => copyToClipBoard(data ?? "-")}
									rev={undefined}
								/>
							</Tooltip>
							<Tooltip placement="bottom" title="View">
								<InfoCircleOutlined
									onClick={() =>
										handleViewJson(
											data ?? "-",
											record,
											"Event Object"
										)
									}
									rev={undefined}
								/>
							</Tooltip>
						</Space>
					) : (
						"-"
					)}
				</Flex>
			),
		},
		{
			title: "Error Code",
			dataIndex: "error_message_code",
			width: 100,
			render: (data: any) => <>{!data ? "-" : data}</>,
		},
		{
			title: "Error Object",
			dataIndex: "error_object",
			width: 110,
			render: (data: any, record: any) => (
				<Flex justify="center">
					{/* <div className="ellipsis-div">{!data ? "-" : data}</div> */}
					{data ? (
						<Space>
							<Tooltip placement="bottom" title="Copy">
								<CopyOutlined
									onClick={() => copyToClipBoard(data ?? "-")}
									rev={undefined}
								/>
							</Tooltip>

							<Tooltip placement="bottom" title="View">
								<InfoCircleOutlined
									onClick={() =>
										handleViewJson(
											data ?? "-",
											record,
											"Error Object"
										)
									}
									rev={undefined}
								/>
							</Tooltip>
						</Space>
					) : (
						"-"
					)}
				</Flex>
			),
		},
	];

	return (
		<Box>
			<Table
				columns={columns}
				dataSource={processLogs}
				// onChange={onChange}
				loading={isLoading}
				size="small"
				pagination={{ defaultPageSize: 20 }}
				className="process-table-fixed-height"
				scroll={{
					y: "60vh",
				}}
			/>
			<ViewJsonTree
				jsonData={viewJsonData}
				isOpen={isModalOpen}
				handleClose={handleClose}
			/>
		</Box>
	);
};

export { ProcessTable };
