import { createContext, useContext, useState } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { TenantProcessTypesType } from "../../types";

dayjs.extend(utc);

export const DashboardContext = createContext({
	date: "",
	setDate: (date: string) => {},
	tenantId: "",
	setTenantId: (tenantId: string) => {},
	refreshData: false,
	setRefreshData: (refreshData: boolean) => {},
	tenantProcessTypes: null,
	setTenantProcessTypes: (types: TenantProcessTypesType | null) => {},
	isLoading: false,
	setIsLoading: (isLoading: boolean) => {},
});

export const useDashboardContext = () => {
	return useContext(DashboardContext);
};

export const DashboardProvider = ({ children }: any) => {
	// const [date, setDate] = useState("2023-11-14");
	const [date, setDate] = useState(dayjs().utc().format("YYYY-MM-DD"));
	const [tenantId, setTenantId] = useState("trillion");
	const [isLoading, setIsLoading] = useState(false);
	const [refreshData, setRefreshData] = useState(false);
	const [tenantProcessTypes, setTenantProcessTypes] =
		useState<TenantProcessTypesType | null>(null);

	const value: any = {
		date,
		setDate,
		tenantId,
		setTenantId,
		refreshData,
		setRefreshData,
		tenantProcessTypes,
		setTenantProcessTypes,
		isLoading,
		setIsLoading,
	};

	return (
		<DashboardContext.Provider value={value}>
			{children}
		</DashboardContext.Provider>
	);
};
